<script>
import { mapActions } from "vuex";
import router from "../router";

import AliceLogoMark from "../components/shared/AliceLogoMark";
import PasswordVisibilityToggle from "../components/Login/PasswordVisibilityToggle.vue";
import PopUpCardModal from "../components/shared/PopUpCardModal.vue";
import { createPassword } from "../services/session";
import { verifyEmail } from "../services/email";
import { reinitializeSocketWithToken } from "../services/socket_background";
import { getInitials } from "../utils/textUtilities";

export default {
  name: 'SignUp',
  props: {},
  components: {
    AliceLogoMark,
    PasswordVisibilityToggle,
    PopUpCardModal,
  },
  data() {
    return {
      fullname: null,
      password: null,
      token: null,
      loading: false,
      signUpClicked: false,
      showPassword: false,
      textMsgError: '',
      isPopupActive: false,
    }
  },
  mounted() {
    const token = this.$route.params.token;
    this.token = token;

    this.logoutCurrentSession();
  },
  computed: {
    isFormValid() {
      return this.isValidPassword && this.isFullnameValid;
    },
    isValidPassword() {
      return this.password?.length >= 8;
    },
    isFullnameValid() {
      if (this.fullname && this.fullname.trim().split(' ').length === 2) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["logoutSession", "setUserSessionAction"]),
    closePopUp() {
      this.$refs.modalError.hide();
    },
    navigateToLogin() {
      router.push({ name: 'login' });
    },
    async submitSignUp() {
      this.signUpClicked = true;
      this.loading = true;
      if (!this.isFormValid) return;
      try {
        const res_data = await createPassword({
          password: this.password,
          name: this.fullname,
          token: this.token,
        });
        if (res_data.error === false) {
          localStorage.setItem("token", res_data.token);
          localStorage.setItem("refresh_token", res_data.refresh_token);
          const save_user = {
            loggedIn: true,
            email: res_data.user.email,
            name: res_data.user.name,
            id_group: res_data.user.id_group,
            initials: getInitials(res_data.user.name),
            image: res_data.user.image,
            permissions: res_data.permiissions,
            suscription: res_data.user.suscription
          };
          localStorage.setItem("user_data", JSON.stringify(save_user));
          this.setUserSessionAction(save_user);
          if (res_data?.user?.show_welcome == true) {
            router.push("/editproject?welcome=true");
          } else {
            router.push("/");
          }
        } else {
          this.textMsgError = res_data.message;
          this.$refs.modalError.showModal(); 
          const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
          delay(1500)
            .then(() => {
                router.push({ name: "login" });
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetErrors() {
      this.signUpClicked = false;
    },
    handleInput() {
      this.resetErrors();
    },
    logoutCurrentSession() {
      this.logoutSession();
    },
    async loginWithToken() {
      try {
        const token = this.$route.params.token;
        if (!token) return;
        const res_data = await verifyEmail({ token });
        this.logoutCurrentSession();
        if (res_data.error === false) {
          reinitializeSocketWithToken(res_data.token);
          localStorage.setItem("token", res_data.token);
          localStorage.setItem("refresh_token", res_data.refresh_token);
          const save_user = {
            loggedIn: true,
            email: res_data.user.email,
            name: res_data.user.name,
            id_group: res_data.user.id_group,
            initials: getInitials(res_data.user.name),
            image: res_data.user.image,
            permissions: res_data.permissions,
            suscription: res_data.user.suscription
          };
          localStorage.setItem("user_data", JSON.stringify(save_user));
          this.setUserSessionAction(save_user);
          if (res_data?.user?.show_welcome == true) {
            router.push("/editproject?welcome=true");
          } else {
            router.push("/");
          }
        } else {
          if (res_data.verified == true) {
            router.push({ name: "login" });
          }
          if (res_data.message) {
            this.modalMessage = res_data.message;
            this.$refs.modalError.showModal();
          } else {
            this.modalMessage = 'An error has occurred.';
            this.$refs.modalError.showModal();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<template>
  <div>
    <b-container class="h-100 mr-auto ml-auto justify-center">
      <b-row align-v="center" class="h-100">
        <b-container>
          <b-row align-h="center" class="login-container">
            <b-row class="mr-0 ml-0">
              <b-col align-self="center">
                <b-container class="login">
                  <main id="login">
                    <section>
                      <router-link to="/login">
                        <AliceLogoMark />
                      </router-link>
                      <h1 class="login-title">
                        Sign up to turn your data into knowledge
                      </h1>

                      <form @submit="submitSignUp" v-on:keyup.enter="submitSignUp" method="post">
                        <div class="sign-up-container">
                          <!-- Full name input -->
                          <div class="input-container">
                            <input type="text" class="fullname-text" placeholder="Your full name" v-model="fullname"
                              autocomplete="off" @input="handleInput" />
                            <p v-if="!isFullnameValid && signUpClicked" class="check-email-indication color-error-login">
                              Please write your first and last name here.
                            </p>
                          </div>

                          <!-- Password input -->
                          <div class="input-container">
                            <input id="password" name="password" :type="showPassword ? 'text' : 'password'"
                              :class="['login-text', !showPassword ? 'space-letter-pass' : '']"
                              placeholder="Your password" v-model="password" autocomplete="new-password"
                              @input="handleInput" />
                          </div>
                        </div>

                        <input :disabled="!isFormValid" @click="submitSignUp" type="button" class="sign-up" value="Sign up" />
                      </form>


                      <!-- HIDE/PASSWORD (EYE)  -->
                      <div class="eye-container" v-on:click="showPassword = !showPassword">
                        <PasswordVisibilityToggle :showPassword="showPassword" />
                      </div>

                      <PopUpCardModal ref="modalError" :isPopupActive="isPopupActive" :closePopUp="closePopUp"
                        titleCard="Sign up" :textMsg="textMsgError" :isOk="true" />
                    </section>
                  </main>
                </b-container>
              </b-col>
            </b-row>
          </b-row>
        </b-container>
      </b-row>
    </b-container>
    <footer class="footer-bobble-graph">
      <img src="../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
    </footer>
  </div>
</template>

<style scoped>
@import "../assets/styles/views/Login.css";

.input-container input[disabled] {
  /* style for inputs disabled */
  background-color: #fff;
  color: #333;
}

.sign-up:disabled {
  cursor: not-allowed;
}
</style>